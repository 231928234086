import React from "react";
import { CollapsePage } from "../../Components";
import "./styles.css";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";

let database = firebaseApp.database().ref("/");
class Freelancing extends React.Component {
  constructor() {
    super();
    this.state = {
      rollNumber: 0,
      Students: [],
      filterArray: [],
      status: "All",
      mobileNum: null,
    };
  }

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });

    let arr = [];
    database.child("Registration/").on("child_added", (res) => {
      let data = res.val();
      if(!data.left && data.isCompleted !== "completed"){
        if (data.course === "Freelancing") {
          if (data.approved !== false) {
            arr.push(data);
          }
          this.setState({ Students: arr });
        }
      }
    });
  }

  render() {
    var filterArr = [];
    let { status, mobileNum } = this.state;

    if (mobileNum === null || mobileNum === "") {
      if (status !== "All") {
        filterArr = this.state.Students.filter((users) => {
          return users && users.approved === this.state.status;
        });
      } else {
        filterArr = this.state.Students.filter((users) => {
          return users;
        });
      }
    } else {
      filterArr = this.state.Students.filter((users) => {
        return (
          users && String(users.mobileNum) === String(this.state.mobileNum)
        );
      });
    }
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="_body">
          <div className="title">Registered Students</div>
          <div className="_filter_view1">
            {/* STUDENT FILETER DROPDOWN */}
            <div className="_student-filter">
              <Grid xs={12} sm={12} md={4} lg={4}>
                <div>
                  <input
                    type="number"
                    style={{ outline: "none" }}
                    placeholder="Enter Mobile Number"
                    value={this.state.mobileNum}
                    onChange={(event) =>
                      this.setState({ mobileNum: event.target.value })
                    }
                  />
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={4} lg={4}>
                <div>
                  <select
                    name="select"
                    className="_dropdown"
                    onChange={(event) =>
                      this.setState({
                        status: event.target.value,
                        mobileNum: null,
                      })
                    }
                  >
                    <option value="All"> All </option>
                    <option value="Approved">Approved </option>
                    <option value="Pending">Unapproved </option>
                  </select>
                </div>
              </Grid>

              <Grid xs={6} sm={6} md={4} lg={4}>
                <button className="button">Search</button>
              </Grid>
            </div>
          </div>
          {filterArr.length !== 0 ? (
            filterArr.map((val, i) => {
              return <CollapsePage data={val} key={i} />;
            })
          ) : (
            <h1>there is now any student enroled yet!</h1>
          )}
        </div>
      </div>
    );
  }
}

export default Freelancing;
