import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Form, Button } from "react-bootstrap";
import MultipleSelect from "./../../Components/MultiSelect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Switch from "@material-ui/core/Switch";
import firebaseApp from "./../../Config/Firebase/Firebase";
import "./index.css";

let database = firebaseApp.database().ref("/");

export default class Admission extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
      checked: false,
      personName: [],
      batches: [],
      batchName: "",
      addmissionFees: "",
      monthlyFees: "",
    };
  }

  componentDidMount() {
    let { batches } = this.state;
    database.child("batches/").on("child_added", (res) => {
      console.log(res.val());
      var data = res.val();
      data.id = res.key;
      batches.push(data);
      this.setState({ batches: batches });
    });
  }

  classes_switch_handle = (event, i) => {
    let { checked, batches } = this.state;
    batches[i].classes = event.target.checked;
    this.setState({ batches: batches });
  };

  handleChange = (event) => {
    this.setState({ personName: event.target.value });
  };

  add_batch = () => {
    let {
      checked,
      batches,
      personName,
      batchName,
      addmissionFees,
      monthlyFees,
    } = this.state;
    if (batchName === "") {
      alert("batch name is undefined");
    } else if (addmissionFees === "") {
      alert("Addmission Fees is undefined");
    } else if (monthlyFees === "") {
      alert("Monthly Fees is undefined");
    } else {
      var obj = {
        batch_name: batchName,
        courses: personName,
        classes: false,
        addmissionFees,
        monthlyFees,
      };

      database
        .child("batches/")
        .push(obj)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  update_batch_date = (i, id) => {
    let {
      checked,
      batches,
      personName,
      batchName,
      addmissionFees,
      monthlyFees,
    } = this.state;
    database
      .child("batches/" + id + "/")
      .set(batches[i])
      .then((res) => alert("updated successfully"))
      .catch((err) => console.log(err.message));
  };

  render() {
    let {
      checked,
      batches,
      personName,
      batchName,
      addmissionFees,
      monthlyFees,
    } = this.state;
    console.log(this.state);
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="container_sec" style={{ marginTop: 100 }}>
          <Container>
            <Grid container justify="center">
              <Grid item lg={10} xs={12}>
                <div>
                  {batches &&
                    batches.map((val, i) => {
                      return (
                        <Accordion style={{ margin: 10 }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{val.batch_name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ backgroundColor: "lightgrey" }}
                          >
                            <div>
                              <h4>Courses</h4>
                              <ol>
                                {val.courses.map((course_name, index) => {
                                  return <li>{course_name}</li>;
                                })}
                              </ol>
                              <p>Addmission Fees: {val.addmissionFees}</p>
                              <p>Monthly Fees: {val.monthlyFees}</p>
                              <div>
                                Classes{" "}
                                <Switch
                                  checked={val.classes}
                                  onChange={(e) =>
                                    this.classes_switch_handle(e, i)
                                  }
                                  color="primary"
                                  name="checkedB"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </div>
                              <button
                                onClick={() =>
                                  this.update_batch_date(i, val.id)
                                }
                                className="batche_save_btn"
                              >
                                Save
                              </button>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </div>
              </Grid>
              <Grid item lg={8} xs={12}>
                <div style={{ width: "100%", marginTop: 100 }}>
                  <h2>Add new batch</h2>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Enter Batch number"
                        style={{ fontSize: 18 }}
                        value={batchName}
                        onChange={(e) =>
                          this.setState({ batchName: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="Enter Addmission fees"
                        style={{ fontSize: 18 }}
                        value={addmissionFees}
                        onChange={(e) =>
                          this.setState({ addmissionFees: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="Enter Monthly fees"
                        style={{ fontSize: 18 }}
                        value={monthlyFees}
                        onChange={(e) =>
                          this.setState({ monthlyFees: e.target.value })
                        }
                      />
                    </Form.Group>

                    <p>Select the course</p>
                    <MultipleSelect
                      personName={this.state.personName}
                      handleChange={this.handleChange}
                    />
                    <Button
                      variant="primary"
                      type="button"
                      style={{ marginTop: 10 }}
                      onClick={() => this.add_batch()}
                    >
                      Add Batch
                    </Button>
                  </Form>
                </div>
              </Grid>


            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
