import React, { Component } from "react";
import { Accordion, Card, Button, Modal } from "react-bootstrap";
import "./Monthlychalan.css";
import swal from "sweetalert";
import firebaseApp from "../../Config/Firebase/Firebase";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
var moment = require("moment-timezone");
var database = firebaseApp.database().ref("/");

class Monthlychalan extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      selectedData: {},
      totalRegistraion: [],
      rollNumData: {},
      newRollNumber: "null",
      viewChlan: "",
      viewCertificate: "",
      modelHeading: "",
      suspend: "suspend",
      monthlyChalan: "unpaid",
      unpaidStudent: [],
      preview: {},
      status: "All",
      rollNumber: null,
    };
  }

  componentDidMount = async () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        let arr = [];
        database.child("Registration/").on("child_added", (res) => {
          var data = res.val();
          if (
            data.monthlyFeesStructure !== undefined &&
            data.monthlyFeesStructure.monthlyPaid === "Pending"
          ) {
            arr.push(data);
            this.setState({ unpaidStudent: arr });
          }
        });
      } else {
        this.props.history.push("/");
      }
    });
  };
  _approved = async () => {
    let { rollNumData, preview } = this.state;
    var currentDate = moment().tz("Pakistan/Islamabad").format("L");
    await database
      .child("Registration/" + preview.uid + "/" + "monthlyFeesStructure/")
      .update({ monthlyPaid: "paid" });
    await database
      .child("Registration/" + preview.uid + "/" + "paidMonths/")
      .push({ status: "paid", approvedDate: currentDate });
    swal({
      title: "Approved monthly fees",
      text: "Now he able to see teaching plan",
      icon: "success",
      button: "Ok",
    });
    this.setState({ modalShow: false });

    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.state.preview.email,
        subject: "Monthly Payment has been received",
        title:
          " Dear student, \n \n Your monthly payment has been approved by iHunar Student's Portal Team. \n \n Regards iHunar Student's Portal Team.",
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    window.location.reload(false);
    
  };

  _Reject = async () => {
    let { preview } = this.state;
    await database
      .child("Registration/" + preview.uid + "/" + "monthlyFeesStructure/")
      .update({ monthlyPaid: false });
    this.setState({ modalShow: false });
    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.state.preview.email,
        subject: "Monthly payment rejected",
        title:
          "Dear Student, \n \n Your monthly payment has been rejected by iHunar Student's Portal Team, kindly upload clear copy of Paid Challan. \n \n Regards iHunar Student's Portal Team.",
      }),
    });
    window.location.reload(false);
  };
  suspendHandle() {
    let uid = this.props.data.uid;

    if (this.state.suspend === "suspend") {
      database
        .child("Registration/" + uid + "/")
        .update({ suspend: this.state.suspend });
      this.setState({ suspend: "Release" });
    } else {
      database
        .child("Registration/" + uid + "/")
        .update({ suspend: this.state.suspend });
      this.setState({ suspend: "suspend" });
    }
  }
  render() {
    let {
      modalShow,
      viewChlan,
      monthlyChalan,
      rollNumber,
      status,
      unpaidStudent,
    } = this.state;
    var filterArr = [];
    if (rollNumber === null || rollNumber === "") {
      if (status !== "All") {
        filterArr = unpaidStudent.filter((users) => {
          return users && users.course === this.state.status;
        });
      } else {
        filterArr = unpaidStudent.filter((users) => {
          return users;
        });
      }
    } else {
      filterArr = unpaidStudent.filter((users) => {
        return (
          users && String(users.rollNumber) === String(this.state.rollNumber)
        );
      });
    }
    return (
      <>
        <TemporaryDrawer props={this.props.history} />
        <div className="_body">
          <div className="title">Paid Students</div>
          <div className="_filter_view1">
            {/* STUDENT FILETER DROPDOWN */}
            <div className="_student-filter">
              <div>
                <input
                  type="number"
                  placeholder="Enter Roll Number"
                  onChange={(event) =>
                    this.setState({ rollNumber: event.target.value })
                  }
                />
              </div>
              <div>
                <select
                  name="select"
                  className="_dropdown"
                  onChange={(event) =>
                    this.setState({ status: event.target.value, rollNum: null })
                  }
                >
                  <option value="All"> All </option>
                  <option value="Freelancing">Freelancing </option>
                  <option value="Web Development & Designing">
                    Web Development & Designing{" "}
                  </option>
                  <option value="Mobile App Development">
                    Mobile App Development{" "}
                  </option>
                  <option value="Graphic Designing">Graphic Designing </option>
                  <option value="Video Editing">Video Editing </option>
                  <option value="E-Commerce">E-Commerce </option>
                  <option value="Digital Marketing">Digital Marketing </option>
                  <option value="Wordpress">Wordpress </option>
                </select>
              </div>
              <button className="button">Search</button>
            </div>
          </div>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
          >
            <Modal.Header
              closeButton
              onClick={() => this.setState({ modalShow: false })}
            >
              <Modal.Title id="contained-modal-title-vcenter">
                {this.state.modelHeading}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={viewChlan} width="450" height="700" />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => this._Reject()}
                style={{ backgroundColor: "red", borderWidth: "0px" }}
              >
                Reject
              </Button>
              <Button onClick={() => this._approved()}>Approve</Button>
            </Modal.Footer>
          </Modal>
          {filterArr.length !== 0 ? (
            filterArr.map((val, i) => {
              return (
                <Accordion defaultActiveKey={false} key={i}>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <img
                              src={val.url}
                              width="50px"
                              height="50px"
                              className="_profile"
                            />
                          </span>
                          <span>{val.name}</span>
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            flexWrap: "nowrap",
                          }}
                        >
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Roll Number
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.rollNumber}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Candidate Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.name}
                            </div>{" "}
                            <div style={{ fontWeight: "bold" }}>
                              Father Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.fatherName}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Course Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.course}
                            </div>
                          </div>
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Mobile Number
                            </div>
                            <div
                              style={{
                                fontFamily: "Sans-serif",
                                marginBottom: "20px",
                              }}
                            >
                              {val.mobileNum}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              qualification
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.qualification}
                            </div>{" "}
                            <div style={{ fontWeight: "bold" }}>
                              Email Address
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.email}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              CNIC Number
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.nicNumber}
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              monthlyChalan !== "unpaid"
                                ? this.setState({ modalShow: false })
                                : this.setState({
                                    modalShow: true,
                                    viewChlan:
                                      val.monthlyFeesStructure.PaidChalan,
                                    preview: val,
                                  })
                            }
                          >
                            {monthlyChalan === "unpaid" ? (
                              <img
                                src={val.monthlyFeesStructure.PaidChalan}
                                width="150px"
                                height="200px"
                              />
                            ) : null}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              <span style={{ color: "black" }}>
                                Monthly Chalan
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            })
          ) : (
            <div>There is no any pending student</div>
          )}
        </div>
      </>
    );
  }
}

export default Monthlychalan;
