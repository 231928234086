import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import { Form, Button } from "react-bootstrap";
import { AiTwotoneEdit } from "react-icons/ai";
import { BsXCircleFill, BsCheck } from "react-icons/bs";
import firebaseApp from "./../../Config/Firebase/Firebase";
import { Spinner, Tabs, Tab, Accordion, Card } from "react-bootstrap";
import "./index.css";
let database = firebaseApp.database().ref("/");
var moment = require("moment-timezone");

export default class CreateJobs extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      title: "",
      //   subTitle: "",
      description: "",
      //   startDate: "",
      //   endDate: "",
      //   subTitleArray: [],
      //   course: "Select Course",
      //   editSubtitle: "",
      errorMessage: "",
      location: "",
      isLoader: false,
    };
  }

  componentDidMount = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });
  };

  addSubtitle = () => {
    let { subTitle, subTitleArray } = this.state;
    if (subTitle === "") {
      alert("fill the field...");
    } else {
      var obj = {
        subTitle,
        edit: false,
      };
      subTitleArray.push(obj);
      this.setState({ subTitleArray: subTitleArray, subTitle: "" });
    }
  };

  setShedule = () => {
    let weeknum = moment(this.state.startDate, "YYYYMMDD").week();
    let year = moment(this.state.startDate, "YYYY").year();
    let {
      course,
      title,
      description,
      startDate,
      endDate,
      subTitleArray,
      location,
    } = this.state;
    if (title === "") {
      this.setState({ errorMessage: "Please fill the title field." });
    } else if (description === "") {
      this.setState({ errorMessage: "Please fill the description field." });
    } else if (location === "") {
      this.setState({ errorMessage: "Please fill the location field." });
    } else {
      this.setState({ isLoader: true });
      database.child("Jobs/").push(
        {
          title,
          description,
          location,
        },
        (error) => {
          if (error) {
            console.log(error.message);
          } else {
            console.log("data send successfully...............");
            this.setState({
              isLoader: false,
              errorMessage: "data send successfully...............",
            });
          }
        }
      );
      this.setState({
        title: "",
        description: "",
        errorMessage: "",
        location: "",
      });
    }
  };
  deleteSubtittle = (i) => {
    let { subTitleArray } = this.state;
    subTitleArray.splice(i, 1);
    console.log(subTitleArray);
    this.setState({ subTitleArray: subTitleArray });
  };
  editSubtitle = (i) => {
    let { subTitleArray, editSubtitle } = this.state;
    subTitleArray[i].edit = true;
    this.setState({
      subTitleArray: subTitleArray,
      editSubtitle: subTitleArray[i].subTitle,
    });
  };
  saveEdit = (i) => {
    let { subTitleArray, editSubtitle } = this.state;
    subTitleArray[i].subTitle = editSubtitle;
    subTitleArray[i].edit = false;
    this.setState({ subTitleArray: subTitleArray });
  };

  render() {
    let {
      checked,
      description,
      subTitle,
      title,
      subTitleArray,
      startDate,
      endDate,
      editSubtitle,
      errorMessage,
      isLoader,
      location,
    } = this.state;
    return (
      <div className="main_div">
        <div></div>
        <TemporaryDrawer props={this.props.history} />
        <Tabs
          defaultActiveKey="Applications"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Applications" title="Applications">
            <Accordion defaultActiveKey={false}>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    className="toggle_outline"
                    as={Button}
                    variant="link"
                    eventKey="0"
                  >
                    <span>image</span>
                    <span>name</span>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div></div>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <div style={{ fontWeight: "bold" }}>
                        iHunar Batch
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.batch} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>Roll Number</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.rollNumber} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>Candidate Name</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.name} */}
                        </div>{" "}
                        <div style={{ fontWeight: "bold" }}>Father Name</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.fatherName} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>Course Name</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.course} */}
                        </div>
                      </Grid>

                      <Grid item xs={6} sm={6} md={3} lg={3}>
                        <div style={{ fontWeight: "bold" }}>Mobile Number</div>
                        <div
                          style={{
                            fontFamily: "Sans-serif",
                            marginBottom: "20px",
                          }}
                        >
                          {/* {this.props.data.mobileNum} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>Course Status</div>
                        <div
                          style={{
                            fontFamily: "Sans-serif",
                            marginBottom: "20px",
                          }}
                        >
                          {/* {this.props.data.isCompleted} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>Qualification</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.qualification} */}
                        </div>{" "}
                        <div style={{ fontWeight: "bold" }}>Email Address</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.email} */}
                        </div>
                        <div style={{ fontWeight: "bold" }}>CNIC Number</div>
                        <div style={{ marginBottom: "20px" }}>
                          {/* {this.props.data.nicNumber} */}
                        </div>
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Tab>
          <Tab eventKey="  Create-Jobs" title="  Create jobs">
            <div className="_main_container">
              <Grid container justify="center">
                <Grid item lg={5} xs={11}>
                  <div className="_teaching_plan_div">
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          value={title}
                          placeholder="Enter Job Title"
                          onChange={(e) =>
                            this.setState({ title: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          value={location}
                          placeholder="Enter Job Location"
                          onChange={(e) =>
                            this.setState({ location: e.target.value })
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          placeholder="Add Description"
                        />
                      </Form.Group>

                      <div
                        style={
                          errorMessage ===
                          "data send successfully..............."
                            ? { color: "green", fontSize: "16px" }
                            : { color: "red", fontSize: "16px" }
                        }
                      >
                        {errorMessage}
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => this.setShedule()}
                      >
                        {isLoader ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
