import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import { Container, Grid } from "@material-ui/core";
import { Form, Button } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import firebaseApp from "./../../Config/Firebase/Firebase";
import "./index.css";
let database = firebaseApp.database().ref("/");
export default class AddDiscount extends React.Component {
  constructor() {
    super();
    this.state = {
      checkedforMales: false,
      checkedforFemales: false,
      disCountForMales: "",
      disCountForFemales: "",
      message: "",
      messageType: "",
    };
  }

  componentDidMount() {
    database.child("discount/").once("value", (res) => {
      console.log(res.val());
      var data = res.val();
      console.log(data);
      this.setState({
        disCountForMales: data.disCountForMales,
        disCountForFemales: data.disCountForFemales,
        checkedforMales: data.checkedforMales,
        checkedforFemales: data.checkedforFemales,
      });
    });
  }

  add_batch = () => {
    let {
      checkedforMales,
      disCountForMales,
      disCountForFemales,
      checkedforFemales,
    } = this.state;
    if (disCountForMales === "") {
      this.setState({
        message: "discount for males is undefined",
        messageType: "error",
      });
    } else if (disCountForFemales === "") {
      this.setState({
        message: "discount for females is undefined",
        messageType: "error",
      });
    } else {
      var obj = {
        disCountForMales,
        disCountForFemales,
        checkedforMales,
        checkedforFemales,
      };
      database
        .child("discount/")
        .set(obj)
        .then((res) => {
          console.log(res);
          this.setState({
            message: "added Successfully",
            messageType: "success",
          });
        })
        .catch((err) => {
          console.log(err.message);
          this.setState({ message: err.message, messageType: "error" });
        });
    }
  };

  render() {
    let {
      checked,
      disCountForMales,
      disCountForFemales,
      message,
      messageType,
      checkedforMales,
      checkedforFemales,
    } = this.state;
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="container_sec" style={{ marginTop: 100 }}>
          <Container>
            <Grid container justify="center">
              <Grid item lg={8} xs={12}>
                <div style={{ width: "100%", marginTop: 100 }}>
                  <h2 className="_discount_heading">Discount</h2>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>For Males</Form.Label>
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="Enter discount"
                        style={{ fontSize: 18 }}
                        value={disCountForMales}
                        onChange={(e) =>
                          this.setState({ disCountForMales: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>For Females</Form.Label>
                      <Form.Control
                        type="number"
                        size="lg"
                        placeholder="Enter discount"
                        style={{ fontSize: 18 }}
                        value={disCountForFemales}
                        onChange={(e) =>
                          this.setState({ disCountForFemales: e.target.value })
                        }
                      />
                    </Form.Group>
                    <div>
                      Discount for Males{" "}
                      <Switch
                        checked={checkedforMales}
                        onChange={(e) =>
                          this.setState({ checkedforMales: !checkedforMales })
                        }
                        color="primary"
                        name="checkedB"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </div>
                    <div>
                      Discount for Females{" "}
                      <Switch
                        checked={checkedforFemales}
                        onChange={(e) =>
                          this.setState({
                            checkedforFemales: !checkedforFemales,
                          })
                        }
                        color="primary"
                        name="checkedB"
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </div>
                    {message === "" ? null : (
                      <p
                        style={
                          messageType === "success"
                            ? {
                                backgroundColor: "#4caf5017",
                                color: "#1dbf73",
                                padding: 10,
                              }
                            : {
                                backgroundColor: "#f443360f",
                                color: "red",
                                padding: 10,
                              }
                        }
                      >
                        {message}
                      </p>
                    )}
                    <Button
                      variant="primary"
                      type="button"
                      style={{ marginTop: 10 }}
                      onClick={() => this.add_batch()}
                    >
                      Save
                    </Button>
                  </Form>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
