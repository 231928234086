import React from "react";

import BasicRouter from "./Config/Router/router";
class App extends React.Component {
  render() {
    return (
      <div>
        <BasicRouter />
      </div>
    );
  }
}
export default App;
