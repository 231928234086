import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "@material-ui/core/AppBar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import Divider from "@material-ui/core/Divider";
import {
  AiOutlineLogout,
  AiOutlineHome,
  AiTwotoneCalendar,
} from "react-icons/ai";
import { BsFillAlarmFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import {
  AiFillCalendar,
  AiFillCopy,
  AiFillWallet,
  AiOutlineNumber,
} from "react-icons/ai";
import { FaCloudUploadAlt } from "react-icons/fa";
import { ImPriceTags } from "react-icons/im";
import { MdSchool } from "react-icons/md";
import Favicon from "./../../assets/favicon.png";
import firebaseApp from "../../Config/Firebase/Firebase";
import Switch from "@material-ui/core/Switch";
import "./Navbar.css";
import iHunarLogo from './../../assets/ihunar-logo.png'
let database = firebaseApp.database().ref("/");
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});
export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let logout = () => {
    firebaseApp
      .auth()
      .signOut()
      .then((res) => {
        props.props.push("/");
      });
  };

  const [launchOffOn, setLaunchOffOn] = React.useState(false);

  const [admissionOffOn, setAdmissionOffOn] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  useEffect(() => {
    database.child("LaunchClasses/").once("value", (res) => {
      console.log(res.val().launchClasses);
      setLaunchOffOn(res.val().launchClasses);
    });
    database.child("Admission/").once("value", (res) => {
      setAdmissionOffOn(res.val().admission);
      console.log(res.val().admission);
    });
    console.log(props.props);
  }, []);

  const launchOfOn = (event) => {
    setLaunchOffOn(!launchOffOn);
    database.child("LaunchClasses/").update({ launchClasses: !launchOffOn });
  };

  const addmissionOfOn = () => {
    setAdmissionOffOn(!admissionOffOn);
    database.child("Admission/").update({ admission: !admissionOffOn });
  };
  const open = Boolean(anchorEl);
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className="iskillers_header">
        <img className="_iskillers_logo" src={Favicon} />
      </div>
      {/* -----------------------------> SIDE BAR PAGES <------------------------- */}
      <List>
        <List>
          <ListItem
            button
            key="Home"
            onClick={() => {
              props.props.push("Home");
            }}
            style={{ fontSize: 12 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiOutlineHome size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Home</div>
            </div>
          </ListItem>
          <ListItem
            button
            key="Send Email"
            onClick={() => {
              props.props.push("Announcement");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Send Email</div>
            </div>
          </ListItem>
          <ListItem
            button
            key="Monthly Chalans"
            onClick={() => {
              props.props.push("Monthlychalan");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiTwotoneCalendar size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Monthly Chalans</div>
            </div>
          </ListItem>
          <ListItem
            button
            key="Remaining Students"
            onClick={() => {
              props.props.push("remainingStudents");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <BsFillPeopleFill size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Remaining Students</div>
            </div>
          </ListItem>
          <ListItem
            button
            key="Fees Reminder"
            onClick={() => {
              props.props.push("monthlyReminder");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <BsFillAlarmFill size="20" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Fees Reminder</div>
            </div>
          </ListItem>
          <ListItem
            button
            key="Teaching Plan"
            onClick={() => {
              props.props.push("Teachingplan");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiFillCalendar size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Teaching Plan</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="Upload Gallery Images"
            onClick={() => {
              props.props.push("GelleryUpload");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <FaCloudUploadAlt size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Upload Gallery Images</div>
            </div>
            {/* <ListItemText primary="Upload Gallery Images"  /> */}
          </ListItem>
          <ListItem
            button
            key="Addmission"
            onClick={() => {
              props.props.push("addmission");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <MdSchool size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Addmission</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="Online Addmission"
            onClick={() => {
              props.props.push("onlineAddmission");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <MdSchool size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Online Addmission</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="Discount"
            onClick={() => {
              props.props.push("discount");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <ImPriceTags size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Discount</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="RollNumber"
            onClick={() => {
              props.props.push("RollNumber");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiOutlineNumber size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Roll Number</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="CreateJobs"
            onClick={() => {
              props.props.push("CreateJobs");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiFillCopy size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Create Jobs</div>
            </div>
          </ListItem>

          <ListItem
            button
            key="CreateInternship"
            onClick={() => {
              props.props.push("CreateInternship");
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon>
                <AiFillWallet size="25" />
              </ListItemIcon>
              <div style={{ fontSize: 14 }}>Create Internship</div>
            </div>
          </ListItem>

          <ListItem button key="Close Admission">
            <ListItemIcon>
              {" "}
              <Switch
                checked={admissionOffOn}
                onChange={addmissionOfOn}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </ListItemIcon>
            <ListItemText primary="Close Admission" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="Log Out" onClick={() => logout()}>
            <ListItemIcon>
              <AiOutlineLogout size="30" />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </List>
    </div>
  );

  return (
    <AppBar>
      <div className="_side_drawer">
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  className="_icon_btn"
                >
                  <MenuIcon color="white" />
                </Button>
                <img
                  src={iHunarLogo}
                  className="_iskillers_navbar_logo"
                />
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </AppBar>
  );
}
