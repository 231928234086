import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import icon from '../../assets/sent-mail.png'
import './style.css'



export default class Months extends React.Component {
    constructor() {
        super()
        this.state = {
            icon: <img src={icon} width="20" height="20" />
        }
    }
    iconHendle() {
        this.setState({ icon: 'Send Reminder' })
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
                <Grid container justify="center" className="_grid">
                    <Grid item xs={11} sm={10} md={6} lg={11}>
                        <Paper style={{ width: "100%" }}>
                            <div style={{ width: "100%", marginTop: '-40px' }}>
                                <p className="_heading" style={{ backgroundColor: "#fff" }}>
                                    <div className="_upersection">
                                        <span>{this.props.month}</span>
                                        <span>{this.props.ammount}</span>
                                        <span>456546456</span>
                                        <span style={{ color: '#6f42c1', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.iconHendle()}>{this.state.icon}</span>
                                    </div>
                                </p>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </>
        );
    }
}