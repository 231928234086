import React from "react";
import Grid from "@material-ui/core/Grid";
import firebaseApp from "../../Config/Firebase/Firebase";
import webicon from "../../assets/web.png";
import graphicsicon from "../../assets/graphic.png";
import appicon from "../../assets/mobile-app.png";
import wordpressicon from "../../assets/wordpress.png";
import freelancingicon from "../../assets/working.png";
import markitingicon from "../../assets/digital-marketing.png";
import eccomerceicon from "../../assets/eccomerce.png";
import domain from "../../assets/domain.png";
import allStudent from "../../assets/check-list.png";
import approvesicon from "../../assets/validation.png";
import pandingicon from "../../assets/pending.png";
import passedicon from "../../assets/find.png";
import "./styles.css";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";

let database = firebaseApp.database().ref("/");
export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      webdevelopment: [],
      mobileAppsDev: [],
      ecommerce: [],
      freelancing: [],
      digitalMarketing: [],
      domainMarketing: [],
      graphicDesign: [],
      vedioEditing: [],
      wordPressDesign: [],
      totalRegistration: [],
      unaprovedStudent: [],
      approvedStudent: [],
      pendingStudent: [],
      passedStudent: [],
      youtubeMastery: [],
      SocialmediaMarketing: [],
      leftStudent: [],
    };
  }

  getPassedStudent = () => {
    let arr = [];
    database.child("PassesStudents/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ passedStudent: arr });
    });
  };

  getleftStudent = () => {
    let arr = [];
    database.child("leftStudents/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ leftStudent: arr });
    });
  };

  componentDidMount = async () => {
    // database.child("RollNumbers/"+"YoutubeMastery/").set({
    //   code: 3100,
    //   course: "Youtube Mastery",
    //   rollNumber: 0,
    // });
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
        this.getPassedStudent();
        this.getleftStudent();
      } else {
        this.props.history.push("/");
      }
    });
    let totalRegistration = [];
    let unaprovedStudent = [];
    let approvedStudent = [];
    let pendingStudent = [];

    database.child("Registration/").on("child_added", (res) => {
      let data = res.val();
      if (!data.left && data.isCompleted !== "completed") {
        if (data.approved !== false) {
          totalRegistration.push(data);
          console.log(totalRegistration);
        }
      }
      this.setState({ totalRegistration: totalRegistration });
      if (data.approved === "Approved") {
        approvedStudent.push(data);
        this.setState({ approvedStudent: approvedStudent });
      } else if (data.approved === "Pending") {
        unaprovedStudent.push(data);
        this.setState({ unaprovedStudent: unaprovedStudent });
      }
    });
  };

  render() {
    let webdevelopment = [];
    let mobileAppsDev = [];
    let ecommerce = [];
    let freelancing = [];
    let digitalMarketing = [];
    let domainMarketing = [];
    let graphicDesign = [];
    let vedioEditing = [];
    let wordPressDesign = [];
    let youtubeMastery = [];
    let SocialmediaMarketing = [];
    let data = this.state.totalRegistration;

    for (var i = 0; i < data.length; i++)
      if (data[i].course === "Web Development & Web Designing") {
        webdevelopment.push(data[i]);
      } else if (data[i].course === "Mobile App Development") {
        mobileAppsDev.push(data[i]);
      } else if (data[i].course === "E-commerce Course") {
        ecommerce.push(data[i]);
      } else if (data[i].course === "Freelancing") {
        freelancing.push(data[i]);
      } else if (data[i].course === "Digital Marketing") {
        digitalMarketing.push(data[i]);
      } else if (data[i].course === "Domain after Market Course") {
        domainMarketing.push(data[i]);
      } else if (data[i].course === "Graphic Design") {
        graphicDesign.push(data[i]);
      } else if (data[i].course === "Video Editing") {
        vedioEditing.push(data[i]);
      } else if (data[i].course === "Wordpress") {
        wordPressDesign.push(data[i]);
      } else if (data[i].course === "Youtube Mastery") {
        youtubeMastery.push(data[i]);
      } else if (data[i].course === "Social Media Marketing") {
        SocialmediaMarketing.push(data[i]);
      }

    let cardData = [
      {
        icon: webicon,
        title: "Web Development",
        count: webdevelopment.length,
        press: "web",
        data: webdevelopment,
      },
      {
        icon: graphicsicon,
        title: "Graphics Designing",
        count: graphicDesign.length,
        press: "Graphics",
        data: graphicDesign,
      },
      {
        icon: appicon,
        title: "Mobile app Development",
        count: mobileAppsDev.length,
        press: "Mobileapp",
        data: mobileAppsDev,
      },
      {
        icon: wordpressicon,
        title: "Wordpress",
        count: wordPressDesign.length,
        press: "Wordpress",
        data: wordPressDesign,
      },
      {
        icon: freelancingicon,
        title: "Freelancing",
        count: freelancing.length,
        press: "Freelancing",
        data: freelancing,
      },
      {
        icon: markitingicon,
        title: "Digital Marketing",
        count: digitalMarketing.length,
        press: "Digitalmarkiting",
        data: digitalMarketing,
      },
      {
        icon: eccomerceicon,
        title: "E-commerce",
        count: ecommerce.length,
        press: "Eccomerce",
        data: ecommerce,
      },
      {
        icon: domain,
        title: "Youtube Mastery",
        count: youtubeMastery.length,
        press: "youtubeMastery",
        data: youtubeMastery,
      },
      {
        icon: domain,
        title: "Social Media Marketing",
        count: SocialmediaMarketing.length,
        press: "SocialmediaMarketing",
        data: youtubeMastery,
      },
      {
        icon: domain,
        title: "Domain",
        count: domainMarketing.length,
        data: domainMarketing,
      },
    ];

    let counterData = [
      {
        icon: allStudent,
        title: "All Students:",
        counter: this.state.totalRegistration.length,
      },
      {
        icon: approvesicon,
        title: "Approved:",
        counter: this.state.approvedStudent.length,
      },
      {
        icon: pandingicon,
        title: "Unapproved:",
        counter: this.state.unaprovedStudent.length,
      },
      {
        icon: allStudent,
        title: "Left:",
        counter: this.state.leftStudent.length,
      },
      {
        icon: passedicon,
        title: "Passed:",
        counter: this.state.passedStudent.length,
      },
    ];

    // console.log("leftStudent============>", this.state.leftStudent);
    // console.log("passedStudent============>", this.state.passedStudent);
    console.log("webdevelopment========-----====>", this.state.webdevelopment);

    return (
      <>
        <TemporaryDrawer props={this.props.history} />
        <div className="_container_sec">
          {/* ******* counter cards ******** */}
          <Grid container justify="center" spacing={3}>
            {counterData.map((v, i) => {
              console.log("----------vvvvvvv------------->", v);
              return (
                <Grid item xs={12} sm={12} md={12} lg={2}>
                  <div
                    className="countCard"
                    onClick={() => {
                      return (
                        v.title === "Passed:"
                          ? this.props.history.push("Passed")
                          : null,
                        v.title === "Left:"
                          ? this.props.history.push("LeftStudents")
                          : null
                      );
                    }}
                  >
                    <img src={v.icon} className="_icon2" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "30px",
                      }}
                    >
                      <div className="_countertext">
                        {v.title}
                        <div className="counter">{v.counter}</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          {/* ***********Courses Card*********** */}
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Courses
          </h1>

          <Grid container>
            {cardData.map((v, i) => {
              return (
                <Grid xs={12} sm={12} md={12} lg={4}>
                  <div
                    onClick={() => {
                      this.props.history.push(v.press, {
                        state: v.data,
                        rollNum: i + 1,
                      });
                    }}
                    className="_stateDiv"
                    style={
                      v.title === "Domain" ? { opacity: 0.1 } : { opacity: 1 }
                    }
                  >
                    <img src={v.icon} className="_icon1" />
                    <div className="_text">{v.title}</div>
                    <div className="_numbering">{v.count}</div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </>
    );
  }
}
