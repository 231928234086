import React from "react";
import { CollapsePage } from "../../Components";
import "./styles.css";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

let database = firebaseApp.database().ref("/");

class Graphics extends React.Component {
  constructor() {
    super();
    this.state = {
      rollNumber: 0,
      Students: [],
      filterArray: [],
      status: "All",
      mobileNum: null,
      batches: [],
      onlineBatches: [],
      selectedBatch: false,
      searchType: "",
      mobileNum: "",
    };
  }
  getBatches = () => {
    let arr = [];
    database.child("batches/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ batches: arr });
    });
  };

  getonlineBatches = () => {
    let arr = [];
    database.child("onlineBatches/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ onlineBatches: arr });
    });
  };

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
        this.getBatches();
        this.getonlineBatches();
      } else {
        this.props.history.push("/");
      }
    });

    let arr = [];
    var rollNum = 100;
    database.child("Registration/").on("child_added", (res) => {
      let data = res.val();
      if (!data.left && data.isCompleted !== "completed") {
        if (data.course === "Graphic Design") {
          data.rollNum = rollNum++;
          if (data.approved !== false) {
            arr.push(data);
          }
          this.setState({ Students: arr });
        }
      }
    });
  }

  render() {
    var filterArr = [];
    let {
      status,
      mobileNum,
      batches,
      selectedBatch,
      searchType,
      onlineBatches,
    } = this.state;
    if (searchType !== "") {
      if (searchType === "status") {
        if (this.state.status !== "All") {
          filterArr = this.state.Students.filter((users) => {
            return users && users.approved === this.state.status;
          });
        } else {
          filterArr = this.state.Students.filter((users) => {
            return users;
          });
        }
      } else if (selectedBatch !== false) {
        if (searchType === "batch") {
          if (selectedBatch !== "All") {
            filterArr = this.state.Students.filter((users) => {
              return users && users.batch === selectedBatch;
            });
          } else {
            filterArr = this.state.Students.filter((users) => {
              return users;
            });
          }
        }
      } else {
        filterArr = this.state.Students.filter((users) => {
          console.log(users.mobileNum);
          return (
            users && Number(users.mobileNum) === Number(this.state.mobileNum)
          );
        });
      }
    } else {
      filterArr = this.state.Students.filter((users) => {
        return users;
      });
    }

    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="_body">
          <div className="onlinetitle">Registered Students</div>
          <div className="_filter_view2"></div>
          <Tabs
            defaultActiveKey="Physical Student"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Physical Student" title="Physical Student">
              {/* STUDENT FILETER DROPDOWN */}
              <div className="_student-filter">
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <select
                      name="select"
                      className="__dropdown"
                      onChange={(event) =>
                        this.setState({
                          selectedBatch: event.target.value,
                          searchType: "batch",
                        })
                      }
                    >
                      <option value="All"> All </option>
                      {batches.map((val, i) => {
                        return (
                          <option value={val.batch_name} key={i}>
                            {val.batch_name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>

                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <input
                      type="number"
                      style={{ outline: "none", width: 150 }}
                      placeholder="Enter Mobile Number"
                      value={this.state.mobileNum}
                      onChange={(event) =>
                        this.setState({
                          mobileNum: event.target.value,
                          searchType: "number",
                        })
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <select
                      name="select"
                      className="__dropdown"
                      onChange={(event) =>
                        this.setState({
                          status: event.target.value,
                          mobileNum: null,
                          searchType: "status",
                        })
                      }
                    >
                      <option value="All"> All </option>
                      <option value="Approved">Approved </option>
                      <option value="Pending">Unapproved </option>
                    </select>
                  </Grid>
                  <Grid xs={10} sm={10} md={3} lg={3}>
                    <button className="button" style={{ marginTop: 5 }}>
                      Search
                    </button>
                  </Grid>
                </Grid>
              </div>
              {filterArr.length !== 0 ? (
                filterArr.map((val, i) => {
                  if (!val.registrationType) {
                    return <CollapsePage data={val} key={i} />;
                  }
                })
              ) : (
                <h1>there is now any student enroled yet!</h1>
              )}
            </Tab>
            <Tab eventKey="Online Student" title="Online Student">
              {/* STUDENT FILETER DROPDOWN */}
              <div className="_student-filter">
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <select
                      name="select"
                      className="__dropdown"
                      onChange={(event) =>
                        this.setState({
                          selectedBatch: event.target.value,
                          searchType: "batch",
                        })
                      }
                    >
                      <option value="All"> All</option>
                      {onlineBatches.map((val, i) => {
                        return (
                          <option value={val.batch_name} key={i}>
                            {val.batch_name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>

                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <input
                      type="number"
                      style={{ outline: "none", width: 150 }}
                      placeholder="Enter Mobile Number"
                      value={this.state.mobileNum}
                      onChange={(event) =>
                        this.setState({
                          mobileNum: event.target.value,
                          searchType: "number",
                        })
                      }
                    />
                  </Grid>

                  <Grid xs={12} sm={12} md={3} lg={3}>
                    <select
                      name="select"
                      className="__dropdown"
                      onChange={(event) =>
                        this.setState({
                          status: event.target.value,
                          mobileNum: null,
                          searchType: "status",
                        })
                      }
                    >
                      <option value="All"> All </option>
                      <option value="Approved">Approved </option>
                      <option value="Pending">Unapproved </option>
                    </select>
                  </Grid>
                  <Grid xs={10} sm={10} md={3} lg={3}>
                    <button className="button" style={{ marginTop: 5 }}>
                      Search
                    </button>
                  </Grid>
                </Grid>
              </div>
              {filterArr.length !== 0 ? (
                filterArr.map((val, i) => {
                  if (val.registrationType === "online") {
                    return <CollapsePage data={val} key={i} />;
                  }
                })
              ) : (
                <h1>there is now any student enroled yet!</h1>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Graphics;
