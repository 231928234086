import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import { Form, Button } from "react-bootstrap";
import firebaseApp from "./../../Config/Firebase/Firebase";
import { Spinner } from "react-bootstrap";
import "./RollNumber.css";
let database = firebaseApp.database().ref("/");
var moment = require("moment-timezone");

export default class RollNumber extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      title: "",
      subTitle: "",
      description: "",
      startDate: "",
      endDate: "",
      subTitleArray: [],
      course: "Select Course",
      editSubtitle: "",
      errorMessage: "",
      isLoader: false,
      courses: [],
      selected: false,
    };
  }

  componentDidMount = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
        let arr = [];
        database.child("RollNumbers/").on("child_added", (res) => {
          let data = res.val();
          data.key = res.key;
          arr.push(data);
          this.setState({ courses: arr });
        });
      } else {
        this.props.history.push("/");
      }
    });
  };

  addSubtitle = () => {
    let { subTitle, subTitleArray } = this.state;
    if (subTitle === "") {
      alert("fill the field...");
    } else {
      var obj = {
        subTitle,
        edit: false,
      };
      subTitleArray.push(obj);
      this.setState({ subTitleArray: subTitleArray, subTitle: "" });
    }
  };

  handleChange = (e) => {
    this.setState({ selected: e.target.value });
    database.child("RollNumbers/" + e.target.value).once("value", (res) => {
      let v = res.val();
      v.key = res.key;
      this.setState({ selected: v });
    });
  };

  inputHandleChange = (v, type) => {
    let { selected } = this.state;
    if (type === "batchCode") {
      selected.code = v;
      this.setState({ selected });
    } else if (type === "roll") {
      selected.rollNumber = v;
      this.setState({ selected });
    }
    console.log(selected);
  };

  updateRollNumber = () => {
    let { selected } = this.state;

    database.child("RollNumbers/" + selected.key).update(selected);
  };
  render() {
    let { errorMessage, isLoader, courses, selected } = this.state;
    return (
      <div className="main_div">
        <TemporaryDrawer props={this.props.history} />

        <div className="_main_container">
          <Grid container justify="center">
            <Grid item lg={5} xs={11}>
              <div className="_teaching_plan_div">
                <Form>
                  <select
                    onChange={(e) => this.handleChange(e)}
                    className="_course_selection"
                    style={{ width: "100%", padding: 15 }}
                  >
                    <option value="Select Course">Select Course</option>

                    {courses.map((val, i) => {
                      return (
                        <option value={val.key} key={i} name={val.key}>
                          {val.course}
                        </option>
                      );
                    })}
                  </select>

                  <Form.Group controlId="formBasicEmail">
                    <div>Course & Batch Code</div>
                    <Form.Control
                      type="text"
                      value={selected ? selected.code : null}
                      disabled={selected ? false : true}
                      onChange={(e) =>
                        this.inputHandleChange(e.target.value, "batchCode")
                      }
                      style={{ height: 50 }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <div>Current Roll Number</div>
                    <Form.Control
                      type="text"
                      disabled={selected ? false : true}
                      value={selected ? selected.rollNumber : null}
                      style={{ height: 50 }}
                      onChange={(e) =>
                        this.inputHandleChange(e.target.value, "roll")
                      }
                    />
                  </Form.Group>

                  <div style={{ color: "red", fontSize: "16px" }}>
                    {errorMessage}
                  </div>
                  <Button
                    variant="primary"
                    onClick={() => this.updateRollNumber()}
                  >
                    {isLoader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "update"
                    )}
                  </Button>
                </Form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
