import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import { Form, Button } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import Container from "@material-ui/core/Container";
import firebaseApp from "./../../Config/Firebase/Firebase";
import { Spinner } from "react-bootstrap";
import { FcApproval } from "react-icons/fc";
import "./gelleryUpload.css";
let database = firebaseApp.database().ref("/");

export default class Teachingplan extends React.Component {
  constructor() {
    super();
    this.state = {
      url: "",
      err: "",
      disabled: false,
      show: "",
      galleryImages: [],
      isloader: false,
      successMessage: "",
      imageUploadLoader: false,
      successfull: false,
    };
  }

  componentDidMount() {
    let { galleryImages } = this.state;
    database.child("galleryImages/").on("child_added", (res) => {
      var gallery_images = res.val();
      gallery_images.uid = res.key;
      galleryImages.push(gallery_images);
      this.setState({
        galleryImages: galleryImages,
      });
    });

    setTimeout(() => {
      this.setState({
        show: "",
      });
    }, 3000);
  }

  getImageURL = async (e, value) => {
    if (e.target.files.length !== 0) {
      let imageName = e.target.files[0].name;
      var imageType = e.target.files[0].type;
      let ref = firebaseApp
        .storage()
        .ref("/")
        .child(`galleryImages/${imageName}`);
      if (imageType === "image/png" || imageType === "image/jpeg") {
        this.setState({
          err: "",
          imageUploadLoader: true,
        });
        await ref.put(e.target.files[0]);
        ref.getDownloadURL().then((url) => {
          this.setState({
            url: url,
            successfull: true,
            imageUploadLoader: false,
          });
        });
      } else {
        this.setState({ err: "Please select file in jpeg or png format" });
      }
    } else {
      this.setState({
        err: "",
      });
    }

    setTimeout(() => {
      this.setState({
        err: "",
      });
    }, 3000);
  };

  uploadImage = () => {
    let { url } = this.state;
    if (url === "") {
      this.setState({
        err: "Please select the image",
      });
    } else {
      this.setState({
        isloader: true,
      });
      database.child("galleryImages/").push(
        {
          src: url,
          width: 4,
          height: 3,
        },
        (error) => {
          if (error) {
            this.setState({
              err: "some thing went wrong",
              isloader: false,
            });
          } else {
            this.setState({
              successMessage: "uploaded Sucessfully",
              isloader: false,
            });
          }
        }
      );
    }

    setTimeout(() => {
      this.setState({
        err: "",
        successMessage: "",
      });
    }, 3000);
  };
  delete_handle = (i) => {
    let { galleryImages } = this.state;
    database.child(`galleryImages/${galleryImages[i].uid}/`).remove();
    galleryImages.splice(i, 1);
    this.setState({
      galleryImages: galleryImages,
    });
  };

  render() {
    let { galleryImages, isloader, imageUploadLoader, successfull } =
      this.state;
    return (
      <div className="main_div">
        <TemporaryDrawer props={this.props.history} />
        <div className="_main_container">
          <Grid container justify="center">
            <Grid item lg={6} xs={11}>
              <div className="_teaching_plan_div">
                <Form>
                  <h2>Upload here website gellery Images</h2>
                  {imageUploadLoader ? (
                    <div style={{ textAlign: "center", padding: 20 }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      {successfull ? null : (
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="file"
                            onChange={(e) => this.getImageURL(e)}
                          />
                        </Form.Group>
                      )}
                      {successfull ? <FcApproval size={40} /> : null}
                    </div>
                  )}
                  <div style={{ color: "green" }}>
                    {this.state.successMessage}
                  </div>
                  <div style={{ color: "red" }}>{this.state.err}</div>
                  <Button variant="primary" onClick={() => this.uploadImage()}>
                    {isloader ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;&nbsp; Uploading...
                      </>
                    ) : (
                      "Upload"
                    )}
                  </Button>
                </Form>
              </div>
            </Grid>
          </Grid>
          <Container>
            <Grid container justify="center">
              {galleryImages &&
                galleryImages.map((val, i) => {
                  return (
                    <Grid item lg={3} xs={11}>
                      <div className="_gallery_images_div">
                        <div
                          className="_gallery_image_delete"
                          style={{
                            textAlign: "right",
                            width: "90%",
                            paddingRight: 10,
                            paddingTop: 30,
                          }}
                        >
                          <AiFillDelete
                            style={{ position: "absolute", cursor: "pointer" }}
                            size={20}
                            color="black"
                            onClick={() => this.delete_handle(i)}
                          />
                        </div>
                        <img src={val.src} className="_gallery_images" />
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Container>
        </div>
        {/* <div id="snackbar" className={this.state.show} ><FcOk />  add successfully</div> */}
      </div>
    );
  }
}
