import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Navbar from "./../../Components/Appbar/Appbar";
import firebaseApp from "../../Config/Firebase/Firebase";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import "./login.css";
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      value: "",
      helperText: "",
    };
  }

  login = () => {
    let data = this.state;
    if (
      data.email === "iskillerspk@gmail.com" &&
      data.password === "iSkillers@PK123"
    ) {
      console.log(data.email)
      console.log(data.password)
      firebaseApp
        .auth()
        .signInWithEmailAndPassword("iskillerspk@gmail.com", "iSkillers@PK123")
        .then((res) => {
          this.setState({ helperText: "signup successfully!", error: false });
          this.props.history.push({
            pathname: "/Home",
            state: {
              uid: res.user.uid,
            },
          });
          localStorage.setItem("teacherEmail", res.user.email);
        })
        .catch((err) => {
          if (
            err.message ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            this.setState({
              helperText: "There is no user record on this email!",
              error: true,
            });
          } else {
            this.setState({ helperText: err.message, error: true });
          }
        });
    } else {
      this.setState({ helperText: "Incorrect email or password !", error: true });
    }
  };

  render() {
    return (
      <div>
        <Navbar />
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={6} lg={4} style={{ marginTop: 100 }}>
            <Paper
              className="_grid"
              style={{
                textAlign: "center",
              }}
            >
              <div className="_header_Section">
                <PersonPinIcon className="admin_icon" />
              </div>

              <h3>LOGIN AS A ADMIN</h3>
              <FormControl
                component="fieldset"
                error={this.state.error}
                style={{ width: "90%", textAlign: "center" }}
              >
                <TextField
                  id="outlined-dense"
                  label="Email"
                  type="email"
                  margin="dense"
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                      error: false,
                      helperText: "",
                    })
                  }
                />
                <br />
                <TextField
                  id="outlined-dense"
                  label="Password"
                  type="password"
                  margin="dense"
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                      error: false,
                      helperText: "",
                    })
                  }
                />
                <FormHelperText>{this.state.helperText}</FormHelperText>
                <br />

                <Button
                  variant="outlined"
                  color="red"
                  style={{
                    width: "100%",
                    backgroundColor: "#293B90",
                    color: "white",
                    margin: "auto",
                    height: "50px",
                  }}
                  onClick={() => this.login()}
                >
                  Login
                </Button>
              </FormControl>
              <br />
              <br />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Login;
