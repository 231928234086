import React from "react";
import { CollapsePage } from "../../Components";
import "./styles.css";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
let database = firebaseApp.database().ref("/");
class Eccomerce extends React.Component {
  constructor() {
    super();
    this.state = {
      rollNumber: 0,
      Students: [],
      filterArray: [],
      status: "All",
      mobileNum: null,
    };
  }

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });

    let arr = [];
    database.child("Registration/").on("child_added", (res) => {
      let data = res.val();
      if(!data.left && data.isCompleted !== "completed"){
        if (data.course === "E-commerce Course") {
          if (data.approved !== false) {
            arr.push(data);
          }
          this.setState({ Students: arr });
        }
      }
    });
  }
  render() {
    // let Students = this.props.location.state.state
    let { Students } = this.state;
    var filterArr = [];
    let { status, mobileNum } = this.state;

    if (mobileNum === null || mobileNum === "") {
      if (status !== "All") {
        filterArr = this.state.Students.filter((users) => {
          return users && users.approved === this.state.status;
        });
      } else {
        filterArr = this.state.Students.filter((users) => {
          return users;
        });
      }
    } else {
      filterArr = this.state.Students.filter((users) => {
        return (
          users && String(users.mobileNum) === String(this.state.mobileNum)
        );
      });
    }
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />

        <div className="_body">
          <div className="title">Registered Students</div>
          <div className="_filter_view1">
            {/* STUDENT FILETER DROPDOWN */}
            <div className="_student-filter">
              <div>
                <input
                  type="number"
                  placeholder="Enter Roll Number"
                  onChange={(event) =>
                    this.setState({ rollNumber: event.target.value })
                  }
                />
              </div>
              <div>
                <select
                  name="select"
                  className="_dropdown"
                  onChange={(event) =>
                    this.setState({
                      status: event.target.value,
                      mobileNum: null,
                    })
                  }
                >
                  <option value="All"> All </option>
                  <option value="Approved">Approved </option>
                  <option value="Pending">Unapproved </option>
                </select>
              </div>
              <button className="button">Search</button>
            </div>
          </div>
          {filterArr.length !== 0 ? (
            Students.map((val, i) => {
              return <CollapsePage data={val} key={i} />;
            })
          ) : (
            <h1>there is now any student enroled yet!</h1>
          )}
        </div>
      </div>
    );
  }
}

export default Eccomerce;
