import React from "react";
import Grid from "@material-ui/core/Grid";
import { Form, FormControl, Button } from "react-bootstrap";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import firebaseApp from "../../Config/Firebase/Firebase";
import "./Announcement.css";
let database = firebaseApp.database().ref("/");

class Mobileapp extends React.Component {
  constructor() {
    super();
    this.state = {
      anounceTitle: "",
      anounceDes: "",
      anounceError: "",
      totalEmails: [],
      course: "All",
      approvedUsers: [],
      subject: "",
      title: "",
    };
  }

  componentDidMount = () => {
    let { totalEmails, course, approvedUsers } = this.state;
    database.child("Registration/").on("child_added", (res) => {
      var users = res.val();
      if (users.approved === "Approved") {
        approvedUsers.push(users);
        totalEmails.push(users.email);
        console.log(users);
        this.setState({
          approvedUsers: approvedUsers,
          totalEmails: totalEmails,
        });
      }
    });
  };

  addAnouncements = () => {
    let { anounceTitle, anounceDes } = this.state;
    if (anounceTitle === "") {
      this.setState({ anounceError: "Enter Announcement Title...." });
    } else if (anounceDes === "") {
      this.setState({ anounceError: "Enter Announcement Description...." });
    } else {
      database
        .child("Alert/")
        .update({ Alert: anounceDes, title: anounceTitle });
      this.setState({ anounceError: "", anounceTitle: "", anounceDes: "" });
    }
  };

  sendReminderHendle = async () => {
    let { totalEmails, subject, title } = this.state;
    if (subject === "") {
      alert("Please fill the field");
    } else if (title === "") {
      alert("Please fill the field");
    } else {
      for (var i = 0; i < totalEmails.length; i++) {
        await fetch("https://mailsenders.herokuapp.com/route/reminder", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tomail: totalEmails[i],
            subject: subject,
            title: title,
          }),
        })
          .then((responece) => responece.json())
          .then((res) => alert("Email Send Successfully"))
          .catch((err) => console.log(err));
      }
    }
    this.setState({ subject: "", title: "" });
  };

  render() {
    let {
      anounceError,
      anounceTitle,
      anounceDes,
      totalEmails,
      title,
      subject,
    } = this.state;
    console.log(totalEmails);
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="announcemenTitle">Announcement Students</div>
        <Grid container justify="center">
          <Grid lg="6" xs="10" sm="12">
            <div className="announcementHeading">
              Send Announcement to Student portal
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid lg="6" xs="10" sm="12">
            <div className="Notifications">
              <FormControl
                placeholder="Announcement Title"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                onChange={(e) =>
                  this.setState({ anounceTitle: e.target.value })
                }
                value={anounceTitle}
              />
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label></Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Announcement Description"
                    rows={3}
                    onChange={(e) =>
                      this.setState({ anounceDes: e.target.value })
                    }
                    value={anounceDes}
                  />
                  <div style={{ color: "red", textAlign: "left" }}>
                    {anounceError}
                  </div>
                </Form.Group>
              </Form>
              <Button
                onClick={() => this.addAnouncements()}
                variant="outline-primary"
              >
                Send Announcement
              </Button>
            </div>
          </Grid>
        </Grid>

        <div>
          <Grid container justify="center">
            <Grid lg="6" xs="10" sm="12">
              <div className="announcementHeading">
                Send Email to Student portal
              </div>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid lg="6" xs="10" sm="12">
              <div className="emailDiv">
                <FormControl
                  placeholder="Email Subject"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={subject}
                  onChange={(e) => this.setState({ subject: e.target.value })}
                />
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label></Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Email Title"
                      rows={3}
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </Form.Group>
                </Form>
                <Button
                  variant="outline-primary"
                  onClick={() => this.sendReminderHendle()}
                >
                  Send Email
                </Button>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Mobileapp;
