import React from "react";
import "./LeftStudents.css";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import { Accordion, Card, Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";

let database = firebaseApp.database().ref("/");
class LeftStudents extends React.Component {
  constructor() {
    super();
    this.state = {
      rollNumber: null,
      Students: [],
      filterArray: [],
      status: "All",
      mobileNum: null,
      batches: [],
      selectedBatch: false,
      searchType: "",
      mobileNum: "",
    };
  }

  getBatches = () => {
    let arr = [];
    database.child("batches/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ batches: arr });
    });
  };

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getBatches();
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });

    let arr = [];
    database.child("leftStudents/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      // if (data.course === "Web Development & Web Designing") {
      //   data.rollNum = rollNum++;
      //   if (data.approved !== false) {
      arr.push(data);
      //   }
      this.setState({ Students: arr });
      // }
    });
  }

  render() {
    console.log(this.state.searchType);

    var filterArr = [];
    let { status, mobileNum, batches, selectedBatch, searchType } = this.state;

    if (searchType !== "") {
      if (searchType === "status") {
        if (this.state.status !== "All") {
          filterArr = this.state.Students.filter((users) => {
            return users && users.approved === this.state.status;
          });
        } else {
          filterArr = this.state.Students.filter((users) => {
            return users;
          });
        }
      } else if (selectedBatch !== false) {
        if (searchType === "batch") {
          if (selectedBatch !== "All") {
            filterArr = this.state.Students.filter((users) => {
              return users && users.batch === selectedBatch;
            });
          } else {
            filterArr = this.state.Students.filter((users) => {
              return users;
            });
          }
        }
      } else {
        filterArr = this.state.Students.filter((users) => {
          console.log(users.mobileNum);
          return (
            users && Number(users.mobileNum) === Number(this.state.mobileNum)
          );
        });
      }
    } else {
      filterArr = this.state.Students.filter((users) => {
        return users;
      });
    }
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="_body">
          <div className="title">Left Students</div>
          <div className="_filter_view1">
            {/* STUDENT FILETER DROPDOWN */}
            <div className="_student-filter" style={{ width: 300 }}>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={3} lg={3}>
                  <input
                    type="number"
                    style={{ outline: "none" }}
                    placeholder="Enter Mobile Number"
                    value={this.state.rollNumber}
                    onChange={(event) =>
                      this.setState({
                        mobileNum: event.target.value,
                        searchType: "number",
                      })
                    }
                    style={{ width: 250 }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          {filterArr.length !== 0 ? (
            filterArr.map((val, i) => {
              return (
                <Accordion defaultActiveKey={false} key={i}>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        className="toggle_outline"
                        as={Button}
                        variant="link"
                        eventKey="0"
                      >
                        <span>
                          <img
                            src={val.url}
                            width="50px"
                            height="50px"
                            className="_profile"
                          />
                        </span>
                        <span>{val.name}</span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div></div>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <div style={{ fontWeight: "bold" }}>
                              Candidate Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.name}
                            </div>{" "}
                            <div style={{ fontWeight: "bold" }}>
                              Father Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.fatherName}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Course Name
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.course}
                            </div>
                          </Grid>

                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <div style={{ fontWeight: "bold" }}>
                              Mobile Number
                            </div>
                            <div
                              style={{
                                fontFamily: "Sans-serif",
                                marginBottom: "20px",
                              }}
                            >
                              {val.mobileNum}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Email Address
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.email}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              CNIC Number
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                              {val.nicNumber}
                            </div>

                          </Grid>
                        </Grid>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            })
          ) : (
            <h4>there is now any student enroled yet!</h4>
          )}
        </div>
      </div>
    );
  }
}

export default LeftStudents;
