import React from "react";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";
import { Form, Button } from "react-bootstrap";
import { AiTwotoneEdit } from "react-icons/ai";
import { BsXCircleFill, BsCheck } from "react-icons/bs";
import firebaseApp from "./../../Config/Firebase/Firebase";
import { Spinner } from "react-bootstrap";
import "./teachingPlan.css";
let database = firebaseApp.database().ref("/");
var moment = require("moment-timezone");

export default class Teachingplan extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      title: "",
      subTitle: "",
      description: "",
      startDate: "",
      endDate: "",
      subTitleArray: [],
      course: "Select Course",
      editSubtitle: "",
      errorMessage: "",
      isLoader: false,
    };
  }

  componentDidMount = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });
  };

  addSubtitle = () => {
    let { subTitle, subTitleArray } = this.state;
    if (subTitle === "") {
      alert("fill the field...");
    } else {
      var obj = {
        subTitle,
        edit: false,
      };
      subTitleArray.push(obj);
      this.setState({ subTitleArray: subTitleArray, subTitle: "" });
    }
  };

  setShedule = () => {
    let weeknum = moment(this.state.startDate, "YYYYMMDD").week();
    let year = moment(this.state.startDate, "YYYY").year();
    let { course, title, description, startDate, endDate, subTitleArray } =
      this.state;
    if (course === "Select Course") {
      this.setState({ errorMessage: "Please select the course." });
    } else if (title === "") {
      this.setState({ errorMessage: "Please fill the title field." });
    } else if (description === "") {
      this.setState({ errorMessage: "Please fill the description field." });
    } else if (startDate === "") {
      this.setState({ errorMessage: "Please select the start date" });
    } else if (endDate === "") {
      this.setState({ errorMessage: "Please select the end date" });
    } else {
      this.setState({ isLoader: true });
      database.child("Shedule/" + course + "/").push(
        {
          title,
          description,
          startDate,
          endDate,
          subTitleArray,
          weeknum,
          year,
          course,
        },
        (error) => {
          if (error) {
            console.log(error.message);
          } else {
            console.log("data send successfully...............");
            this.setState({ isLoader: false });
          }
        }
      );
      this.setState({
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        subTitleArray: [],
        errorMessage: "",
        course: "Select Course",
      });
    }
  };
  deleteSubtittle = (i) => {
    let { subTitleArray } = this.state;
    subTitleArray.splice(i, 1);
    console.log(subTitleArray);
    this.setState({ subTitleArray: subTitleArray });
  };
  editSubtitle = (i) => {
    let { subTitleArray, editSubtitle } = this.state;
    subTitleArray[i].edit = true;
    this.setState({
      subTitleArray: subTitleArray,
      editSubtitle: subTitleArray[i].subTitle,
    });
  };
  saveEdit = (i) => {
    let { subTitleArray, editSubtitle } = this.state;
    subTitleArray[i].subTitle = editSubtitle;
    subTitleArray[i].edit = false;
    this.setState({ subTitleArray: subTitleArray });
  };

  render() {
    let {
      checked,
      description,
      subTitle,
      title,
      subTitleArray,
      startDate,
      endDate,
      editSubtitle,
      errorMessage,
      isLoader,
    } = this.state;
    return (
      <div className="main_div">
        <TemporaryDrawer props={this.props.history} />

        <div className="_main_container">
          <Grid container justify="center">
            <Grid item lg={5} xs={11}>
              <div className="_teaching_plan_div">
                <Form>
                  <select
                    onChange={(e) => this.setState({ course: e.target.value })}
                    className="_course_selection"
                  >
                    <option value="Select Course">Select Course</option>
                    <option value="Web Development & Web Designing">
                      Web Development & Web Designing
                    </option>
                    <option value="Mobile App Development">
                      Mobile App Development
                    </option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Video Editing">Video Editing</option>
                    <option value="Wordpress">Wordpress</option>
                    <option value="E-commerce Course">E-commerce Course</option>
                    <option value="Graphic Design">Graphic Design</option>
                    <option value="Freelancing">Freelancing</option>
                    <option value="Domain after Market Course">
                      Domain after Market Course
                    </option>
                  </select>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      value={title}
                      placeholder="Enter Title"
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="want to add subtitle ?"
                      onChange={(e) =>
                        this.setState({ checked: e.target.checked })
                      }
                    />
                  </Form.Group>
                  {checked === true ? (
                    <>
                      <Grid container justify="center">
                        <Grid item lg={10} xs={8}>
                          <div style={{}}>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Control
                                type="text"
                                placeholder="Enter Subtitle"
                                value={subTitle}
                                onChange={(e) =>
                                  this.setState({ subTitle: e.target.value })
                                }
                              />
                            </Form.Group>
                          </div>
                        </Grid>
                        <Grid item lg={2} xs={3}>
                          <div style={{ textAlign: "center" }}>
                            <Button
                              variant="primary"
                              style={{ width: "90%" }}
                              onClick={() => this.addSubtitle()}
                            >
                              ADD
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="_topics_coloumn">
                        {subTitleArray &&
                          subTitleArray.map((val, i) => {
                            return (
                              <div className="_topics_row" key={i}>
                                {val.edit ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editSubtitle}
                                      onChange={(e) =>
                                        this.setState({
                                          editSubtitle: e.target.value,
                                        })
                                      }
                                    />{" "}
                                    <button
                                      type="button"
                                      onClick={() => this.saveEdit(i)}
                                      className="_save_btn"
                                    >
                                      <BsCheck />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <div>{val.subTitle}</div>
                                    <AiTwotoneEdit
                                      color="grey"
                                      size="15"
                                      className="_edit_icon"
                                      onClick={() => this.editSubtitle(i)}
                                    />
                                    <BsXCircleFill
                                      color="grey"
                                      size="15"
                                      className="_delete_icon"
                                      onClick={() => this.deleteSubtittle(i)}
                                    />
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </>
                  ) : null}
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder="Add Description"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="date"
                      placeholder="Enter Start Date"
                      value={startDate}
                      onChange={(e) =>
                        this.setState({ startDate: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="date"
                      placeholder="Enter End Date"
                      value={endDate}
                      onChange={(e) =>
                        this.setState({ endDate: e.target.value })
                      }
                    />
                  </Form.Group>
                  <div style={{ color: "red", fontSize: "16px" }}>
                    {errorMessage}
                  </div>
                  <Button variant="primary" onClick={() => this.setShedule()}>
                    {isLoader ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
