import React from "react";
import firebaseApp from "./../../Config/Firebase/Firebase";
import "./style.css";
import Months from "../../Components/issueChalanMonth/issueChalan";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
export default class Issuechalan extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });
  };
  render() {
    return (
      <>
        <TemporaryDrawer props={this.props.history} />
        <div className="main_div">
          <span className="_title">
            <h1>Send Chalan To Student</h1>
          </span>
          <Months month="1/Month" ammount="Ammount: 3000" />
          <Months month="2/Month" ammount="Ammount: 3000" />
          <Months month="3/Month" ammount="Ammount: 3000" />
          <Months month="4/Month" ammount="Ammount: 3000" />
          <Months month="5/Month" ammount="Ammount: 3000" />
          <Months month="6/Month" ammount="Ammount: 3000" />
        </div>
      </>
    );
  }
}
