import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import firebaseApp from "./../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import icon from "../../assets/delete.png";
import "./index.css";
let database = firebaseApp.database().ref("/");
var moment = require("moment-timezone");

export default class MonthlyReminder extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedCourse: "",
      disabled: false,
      url: "",
      manageUsers: [],
      isDone: false,
      totalEmails: [],
    };
  }
  componentDidMount() {
    let { manageUsers } = this.state;
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });
    let totalEmails = [];
    database.child("Registration/").on("child_added", (res) => {
      var users = res.val();
      if (
        users.approved === "Approved" &&
        users.monthlyFeesStructure &&
        users.monthlyFeesStructure.monthlyPaid === false &&
        users.monthlyFeesStructure.isCompleted === "inProgress"
      ) {
        console.log(users);
        totalEmails.push(users.email);
        manageUsers.push(users);
        this.setState({ manageUsers: manageUsers, totalEmails: totalEmails });
      }
    });
  }
  sendReminderHendle = async () => {
    let { totalEmails } = this.state;
    await fetch("https://mailsenders.herokuapp.com/route/reminder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: totalEmails,
        subject: "Payment reminder for monthly fees",
        title:
          " Dear Student! kindly pay your monthly fees to continue your classes. Regards iHunar Student's Portal Team",
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  render() {
    let { manageUsers } = this.state;
    console.log(manageUsers);
    return (
      <>
        <TemporaryDrawer props={this.props.history} />
        <Grid container justify="center" className="_grid">
          <Grid item xs={11} sm={10} md={6} lg={9}>
            {manageUsers.length === 0 ? (
              <div>There is no any student</div>
            ) : (
              <Paper style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <div className="_filter_view1">
                    <Button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "0px",
                        outline: "none",
                        margin: 10,
                      }}
                      onClick={() => this.sendReminderHendle()}
                    >
                      Send Reminder
                    </Button>
                  </div>
                  <p className="_heading" style={{ backgroundColor: "#fff" }}>
                    {manageUsers &&
                      manageUsers.map((val, i) => {
                        return (
                          <div className="_upersection">
                            <span>
                              <img
                                src={val.url}
                                width="40"
                                height="40"
                                className="pro_image"
                              />
                            </span>
                            <span>{val.name}</span>
                            <span>{val.mobileNum}</span>
                            <span
                              style={{ color: "#6f42c1", fontWeight: "bold" }}
                            >
                              <img
                                src={icon}
                                width="20"
                                height="20"
                                onClick={() => {
                                  this.delete(val);
                                }}
                              />
                            </span>
                          </div>
                        );
                      })}
                  </p>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
