import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  Login,
  ForgotPassword,
  Home,
  Web,
  Video,
  Graphics,
  Freelancing,
  YoutubeMastery,
  SocialmediaMarketing,
  Domain,
  Wordpress,
  Mobileapp,
  Digitalmarkiting,
  Eccomerce,
  RemainingStudents,
  Issuechalan,
  Monthlychalan,
  MonthlyReminder,
  Announcement,
  Teachingplan,
  GelleryUpload,
  Addmission,
  AddDiscount,
  RollNumber,
  Passed,
  LeftStudents,
  CreateJobs,
  CreateInternship,
  OnlineAddmission
} from "./../../Container";

export default class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Login} />
          <Route path="/Home" component={Home} />
          <Route path="/Web" component={Web} />
          <Route path="/Video" component={Video} />
          <Route path="/Graphics" component={Graphics} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/Freelancing" component={Freelancing} />
          <Route path="/YoutubeMastery" component={YoutubeMastery} />
          <Route path="/SocialmediaMarketing" component={SocialmediaMarketing} />
          <Route path="/Domain" component={Domain} />
          <Route path="/Wordpress" component={Wordpress} />
          <Route path="/Mobileapp" component={Mobileapp} />
          <Route path="/Digitalmarkiting" component={Digitalmarkiting} />
          <Route path="/Eccomerce" component={Eccomerce} />
          <Route path="/remainingStudents" component={RemainingStudents} />
          <Route path="/Issuechalan" component={Issuechalan} />
          <Route path="/Monthlychalan" component={Monthlychalan} />
          <Route path="/monthlyReminder" component={MonthlyReminder} />
          <Route path="/Announcement" component={Announcement} />
          <Route path="/Teachingplan" component={Teachingplan} />
          <Route path="/GelleryUpload" component={GelleryUpload} />
          <Route path="/addmission" component={Addmission} />
          <Route path="/onlineAddmission" component={OnlineAddmission} />
          <Route path="/discount" component={AddDiscount} />
          <Route path="/rollnumber" component={RollNumber} />
          <Route path="/passed" component={Passed} />
          <Route path="/LeftStudents" component={LeftStudents} />
          <Route path="/CreateJobs" component={CreateJobs} />
          <Route path="/CreateInternship" component={CreateInternship} />

        </div>
      </Router>
    );
  }
}
