import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "../../Components/Navbar/Navbar";
import "./styles.css";
let database = firebaseApp.database().ref("/");
var moment = require("moment-timezone");

export default class UploadChalan extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedCourse: "",
      disabled: false,
      url: "",
      manageUsers: [],
      isDone: false,
      totalEmails: [],
      subscripe: "",
      isSending: false,
      batches: [],
      status: "All",
    };
  }
  componentDidMount() {
    let { manageUsers, batches } = this.state;
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });
    let totalEmails = [];
    database.child("Registration/").on("child_added", (res) => {
      var users = res.val();
      if (users.approved === false) {
        if (users.subscripe === true || users.subscripe === undefined) {
          totalEmails.push(users.email);
        }
        manageUsers.push(users);
        this.setState({ manageUsers: manageUsers, totalEmails: totalEmails });
      }
    });

    database.child("Admin/" + "reminder/").once("value", (res) => {
      let data = res.val();
      if (data.pastreminderDate === moment().format("L")) {
        this.setState({ isDone: false });
      } else {
        this.setState({ isDone: true });
      }
    });

    database.child("batches/").on("child_added", (res) => {
      console.log(res.val());
      batches.push(res.val());
      this.setState({ batches: batches });
    });
  }
  addChalanCopy = () => {
    let { selectedCourse, url } = this.state;
    let obj = {
      course: selectedCourse,
      chalan: url,
    };
    if (selectedCourse !== "" && url !== "") {
      database.child(`chalanCopy/${selectedCourse}/`).set(obj);
      this.setState({ url: "", disabled: false, selectedCourse: "" });
    } else {
      alert("please select course or chalan copy");
    }
  };

  getImageURL = async (e) => {
    this.setState({ disabled: true });
    let imageName = e.target.files[0].name;
    let ref = firebaseApp
      .storage()
      .ref("/")
      .child(`chalan/${this.state.selectedCourse}`);
    await ref.put(e.target.files[0]);
    ref.getDownloadURL().then((url) => {
      this.setState({ url: url, disabled: false });
    });
  };

  deleteUser = async (uid) => {
    let axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    };
  };

  delete(val) {
    var user = firebaseApp.auth();
  }
  sendReminderHendle = async () => {
    let { totalEmails, batches } = this.state;
    this.setState({
      isSending: true,
    });

    for (var i = 0; i < totalEmails.length; i++) {
      await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tomail: totalEmails[i],
          subject: "Reminder for payment of admission fees",
          title: `Dear Student, \n \n Kindly pay your admission fees via payment methods given on website in order to confirm your admission for ${
            batches && batches[batches.length - 1].batch_name
          } in IT skills trainings & Job creation Program at iHunar. \n \n for further detail contact. 03322511711 \n \n Regards iHunar Student's Portal Team `,
        }),
      })
        .then((responece) => responece.json())
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
    this.setState({
      isSending: false,
    });
  };

  unsubsribe = (data, index) => {
    let { checked, manageUsers } = this.state;
    if (manageUsers[index].subscripe) {
      manageUsers[index].subscripe = false;
      this.setState({ manageUsers });
      database
        .child("Registration/" + manageUsers[index].uid + "/")
        .update({ subscripe: false });
    } else {
      manageUsers[index].subscripe = true;
      database
        .child("Registration/" + manageUsers[index].uid + "/")
        .update({ subscripe: true });

      this.setState({ manageUsers });
    }
  };

  render() {
    let { manageUsers, isDone, totalEmails, isSending, batches } = this.state;
    console.log(this.state.rollNumber);
    var filterArr = [];
    let { status } = this.state;

    if (status !== "All") {
      filterArr = this.state.manageUsers.filter((users) => {
        return users && users.batch === this.state.status;
      });
    } else {
      filterArr = this.state.manageUsers.filter((users) => {
        return users;
      });
    }

    console.log(totalEmails);
    return (
      <>
        <TemporaryDrawer props={this.props.history} />
        <Grid container justify="center" className="_grid">
          <Grid item xs={11} sm={10} md={6} lg={9}>
            <Paper style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div className="_filter_view1">
                  <span style={{ fontSize: 20, fontFamily: "Monaco" }}>
                    <span style={{ fontWeight: "bold", fontFamily: "Monaco" }}>
                      {" "}
                      Total Students{" "}
                    </span>
                    {manageUsers && manageUsers.length}
                  </span>
                  {isDone ? (
                    <Button
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "0px",
                        outline: "none",
                        margin: 10,
                      }}
                      onClick={() => this.sendReminderHendle()}
                    >
                      {isSending ? "Sending...." : "Send Reminder"}
                    </Button>
                  ) : (
                    <Button
                      disabled
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        border: "0px",
                        outline: "none",
                        margin: 10,
                      }}
                      onClick={() => this.sendReminderHendle()}
                    >
                      you already sent reminder
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "99%",
                    margin: "20px auto 20px auto",
                  }}
                >
                  <div>
                    <select
                      name="select"
                      className="_dropdown"
                      onChange={(event) =>
                        this.setState({
                          status: event.target.value,
                        })
                      }
                    >
                      <option value="All">All</option>
                      {batches &&
                        batches.map((val, i) => {
                          return (
                            <option value={val.batch_name}>
                              {val.batch_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <p className="_heading" style={{ backgroundColor: "#fff" }}>
                  {filterArr &&
                    filterArr.map((val, i) => {
                      return (
                        <div className="_upersection">
                          <img
                            src={val.url}
                            width="40"
                            height="40"
                            className="pro_image"
                          />
                          <span>{val.name}</span>
                          <span>{val.course}</span>
                          <span>{val.mobileNum}</span>
                          <span>{val.email}</span>
                          <span
                            style={{
                              color: "#6f42c1",
                              fontWeight: "bold",
                              textAlign: "right",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="reminder"
                              className="_subscription_checkbox"
                              checked={
                                val.subscripe || val.subscripe === undefined
                                  ? false
                                  : true
                              }
                              onChange={() => this.unsubsribe(val, i)}
                            />
                          </span>
                        </div>
                      );
                    })}
                </p>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
