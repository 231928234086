import React, { Component } from "react";
import { Accordion, Card, Button, Modal } from "react-bootstrap";
import "./allStudents.css";
import swal from "sweetalert";
import firebaseApp from "../../Config/Firebase/Firebase";
import Grid from "@material-ui/core/Grid";
import { TrendingUpRounded } from "@material-ui/icons";

var database = firebaseApp.database().ref("/");

class CollapsePage extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      selectedData: {},
      totalRegistraion: [],
      rollNumData: {},
      newRollNumber: "null",
      viewChlan: "",
      viewCertificate: "",
      modelHeading: "",
      suspend: "suspend",
      left: false,
      studentData: null,
      certificateRejectMsg:
        "Dear Student, \n \n Your Certificate has been rejected by \n \n iHunar Student's Portal Team.",
      chalanRejectMsg:
        " Dear Student, \n \n Unfortunately, your admission has been rejected by iHunar Student's Portal Team. \n \n For further Detail contact. 03322511711 \n \n Regards iHunar Student's Portal Team ",
    };
  }

  componentDidMount = async () => {
    let uid = this.props.data.uid;
    database.child("RollNumbers/").once("value", (res) => {
      let v = res.val();
      this.setState({ rollNumData: v });
    });

    database.child("Registration/" + uid + "/").once("value", (res) => {
      var suspendedStudent = res.val();
      this.setState({ studentData: suspendedStudent });

      if (
        suspendedStudent.suspend === undefined ||
        suspendedStudent.suspend === null
      ) {
        this.setState({ suspend: "suspend" });
      } else if (
        suspendedStudent.suspend !== undefined &&
        suspendedStudent.suspend === "suspend"
      ) {
        this.setState({ suspend: "release" });
      } else {
        this.setState({ suspend: "suspend" });
      }
    });
  };
  _approved = async () => {
    let { rollNumData } = this.state;
    let v = this.state.totalRegistraion;
    let uid = this.props.data.uid;
    let selectedCourse = this.props.data.course;
    var _code = "";
    var lastRollNumber = rollNumData.lastRollNumber;
    var course = "";
    if (selectedCourse === "Web Development & Web Designing") {
      course = "webdevelopment";

      _code = rollNumData.webdevelopment.code;
      lastRollNumber = rollNumData.webdevelopment.rollNumber + 1;
    } else if (selectedCourse === "Mobile App Development") {
      course = "mobileAppsDev";
      _code = rollNumData.mobileAppsDev.code;
      lastRollNumber = rollNumData.mobileAppsDev.rollNumber + 1;
    } else if (selectedCourse === "Digital Marketing") {
      course = "digitalMarketing";

      _code = rollNumData.digitalMarketing.code;
      lastRollNumber = rollNumData.digitalMarketing.rollNumber + 1;
    } else if (selectedCourse === "Video Editing") {
      course = "vedioEditing";
      _code = rollNumData.vedioEditing.code;
      lastRollNumber = rollNumData.vedioEditing.rollNumber + 1;
    } else if (selectedCourse === "Wordpress") {
      course = "wordPressDesign";
      _code = rollNumData.wordPressDesign.code;
      lastRollNumber = rollNumData.wordPressDesign.rollNumber + 1;
    } else if (selectedCourse === "E-commerce Course") {
      course = "ecommerce";
      _code = rollNumData.ecommerce.code;
      lastRollNumber = rollNumData.ecommerce.rollNumber + 1;
    } else if (selectedCourse === "Graphic Design") {
      course = "graphicDesign";
      _code = rollNumData.graphicDesign.code;
      lastRollNumber = rollNumData.graphicDesign.rollNumber + 1;
    } else if (selectedCourse === "Freelancing") {
      course = "freelancing";
      _code = rollNumData.freelancing.code;
      lastRollNumber = rollNumData.freelancing.rollNumber + 1;
    } else if (selectedCourse === "Youtube Mastery") {
      course = "Youtube Mastery";
      _code = rollNumData.youtubeMastery.code;
      lastRollNumber = rollNumData.youtubeMastery.rollNumber + 1;
    } else if (selectedCourse === "Social Media Marketing") {
      course = "Social Media Marketing";
      _code = rollNumData.SocialmediaMarketing.code;
      lastRollNumber = rollNumData.SocialmediaMarketing.rollNumber + 1;
    } else if (selectedCourse === "Domain after Market Course") {
      course = "domainMarketing";
      _code = rollNumData.domainMarketing.code;
      lastRollNumber = rollNumData.domainMarketing.rollNumber + 1;
    }
    database.child("Registration/" + uid + "/").update({
      approved: "Approved",
      rollNumber: _code + "" + lastRollNumber,
    });
    database.child("students/" + uid + "/").update({
      approved: "Approved",
      rollNumber: _code + "" + lastRollNumber,
    });
    database
      .child("RollNumbers/" + course + "/")
      .update({ rollNumber: lastRollNumber });
    this.setState({ newRollNumber: _code + "" + lastRollNumber });

    swal({
      title: "Approved Successfuly",
      text: "Now Your The Part Of iHunar",
      icon: "success",
      button: "Ok",
    });
    this.setState({ modalShow: false });
    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.props.data.email,
        subject: "Admission application approved",
        title:
          "Congratulations! Dear Student \n \n Your admission has been approved for Batch 01 in IT skills trainings & Job creation Program at iHunar. \n \n Regards iHunar Student's Portal Team ",
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    window.location.reload(false);
  };

  _Reject = async () => {
    let uid = this.props.data.uid;
    // alert(this.props.data.email)
    let selectedCourse = this.props.data.course;
    database.child("Registration/" + uid + "/").update({ approved: false });

    swal({
      title: "Reject Successfuly",
      text: "",
      icon: "success",
      button: "Ok",
    });
    this.setState({ modalShow: false });
    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.props.data.email,
        subject: "Admission rejected!",
        title:
          this.state.modelHeading === "Certificate"
            ? this.state.certificateRejectMsg
            : this.state.chalanRejectMsg,
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    window.location.reload(false);
  };
  suspend = async () => {
    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.props.data.email,
        subject: "Your classes have been suspended",
        title:
          "Dear Student, \n  \n Your classes have been suspended due to remaining absent for long time/Not Payment of fees. \n \n For further Detail contact. 03322511711 \n \n Regards iHunar Student's Portal Team ",
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  release = async () => {
    await fetch("https://mailsenders.herokuapp.com/route/sendemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tomail: this.props.data.email,
        subject: "Your classes have been reinstated ",
        title:
          "Dear Student, \n \n Your classes have been reinstated by, iHunar Student's Portal Team, now you can continue your classes \n \n Regards iHunar Student's Portal Team",
      }),
    })
      .then((responece) => responece.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  suspendHandle() {
    let uid = this.props.data.uid;
    if (this.state.suspend === "suspend") {
      this.suspend();
      database
        .child("Registration/" + uid + "/")
        .update({ suspend: this.state.suspend });
      this.setState({ suspend: "Release" });
    } else {
      this.release();
      database
        .child("Registration/" + uid + "/")
        .update({ suspend: this.state.suspend });
      this.setState({ suspend: "suspend" });
    }
  }

  leftStudentHandle() {
    let { left } = this.state;
    let uid = this.props.data.uid;
    database
      .child("Registration/" + uid + "/")
      .update({ left: true })
      .then((res) => {
        database
          .child("leftStudents/")
          .push(this.props.data)
          .then(() => {
            window.location.reload();
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  PassedStudentHandle = () => {
    let uid = this.props.data.uid;
    let { studentData } = this.state;
    var completedCourse = studentData?.completedCourse;
    var completedCourseObj = {
      course: studentData.course,
      email: studentData.email,
      fatherName: studentData.fatherName,
      mobileNum: studentData.mobileNum,
      name: studentData.name,
      nicNumber: studentData.nicNumber,
      url: studentData.url,
    };
    if (studentData.completedCourse) {
      completedCourse.push(completedCourseObj);
    } else {
      completedCourse = [completedCourseObj];
    }

    database
      .child("Registration/" + uid + "/")
      .update({ isCompleted: "completed", completedCourse: completedCourse })
      .then((res) => {
        database
          .child("PassesStudents/")
          .push(this.props.data)
          .then(() => {
            window.location.reload();
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    let {
      modalShow,
      newRollNumber,
      rollNumData,
      viewChlan,
      leftmodalShow,
      passedmodalShow,
    } = this.state;
    return (
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
        >
          <Modal.Header
            closeButton
            style={{ outline: "none" }}
            onClick={() => this.setState({ modalShow: false })}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.modelHeading}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={viewChlan} width="450" height="700" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.modelHeading === "Certificate" ? (
              <Button
                onClick={() => this._Reject()}
                style={{ backgroundColor: "red", borderWidth: "0px" }}
              >
                Reject
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => this._Reject()}
                  style={{ backgroundColor: "red", borderWidth: "0px" }}
                >
                  Reject
                </Button>
                <Button onClick={() => this._approved()}>Approve</Button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Accordion defaultActiveKey={false}>
          <Card>
            <Card.Header>
              <Accordion.Toggle
                className="toggle_outline"
                as={Button}
                variant="link"
                eventKey="0"
              >
                <span>
                  <img
                    src={this.props.data.url}
                    width="50px"
                    height="50px"
                    className="_profile"
                  />
                </span>
                <span>{this.props.data.name}</span>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div></div>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <div style={{ fontWeight: "bold" }}>iHunar Batch</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.batch}
                    </div>
                    <div style={{ fontWeight: "bold" }}>Roll Number</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.rollNumber}
                    </div>
                    <div style={{ fontWeight: "bold" }}>Candidate Name</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.name}
                    </div>{" "}
                    <div style={{ fontWeight: "bold" }}>Father Name</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.fatherName}
                    </div>
                    <div style={{ fontWeight: "bold" }}>Course Name</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.course}
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <div style={{ fontWeight: "bold" }}>Mobile Number</div>
                    <div
                      style={{ fontFamily: "Sans-serif", marginBottom: "20px" }}
                    >
                      {this.props.data.mobileNum}
                    </div>
                    <div style={{ fontWeight: "bold" }}>Course Status</div>
                    <div
                      style={{ fontFamily: "Sans-serif", marginBottom: "20px" }}
                    >
                      {this.props.data.isCompleted}
                    </div>
                    <div style={{ fontWeight: "bold" }}>Qualification</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.qualification}
                    </div>{" "}
                    <div style={{ fontWeight: "bold" }}>Email Address</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.email}
                    </div>
                    <div style={{ fontWeight: "bold" }}>CNIC Number</div>
                    <div style={{ marginBottom: "20px" }}>
                      {this.props.data.nicNumber}
                    </div>
                  </Grid>

                  <Grid itemxs={12} sm={12} md={3} lg={3}>
                    <div
                      onClick={() =>
                        this.props.data.approved !== "Pending"
                          ? this.setState({ modalShow: false })
                          : this.setState({
                              modalShow: true,
                              viewChlan: this.props.data.PaidChalan,
                              modelHeading: "Paid Chalan",
                            })
                      }
                    >
                      {this.props.data.approved === "Approved" ? (
                        <span className="paidStatus">
                          <h5 style={{ color: "#2e3990" }}>
                            He is the part of iHunar
                          </h5>{" "}
                          <Button
                            onClick={() => this.suspendHandle()}
                            style={{ outline: "none" }}
                          >
                            {this.state.suspend}
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ leftmodalShow: true })
                            }
                            style={{ outline: "none", margin: 15 }}
                          >
                            Left Student
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({ passedmodalShow: true })
                            }
                            style={{ outline: "none", margin: 15 }}
                          >
                            Passed Student
                          </Button>
                          <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={passedmodalShow}
                          >
                            <Modal.Header
                              closeButton
                              style={{ outline: "none" }}
                              onClick={() =>
                                this.setState({ passedmodalShow: false })
                              }
                            >
                              <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.modelHeading}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure to would like to Passed Student?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  this.setState({ passedmodalShow: false })
                                }
                              >
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => this.PassedStudentHandle()}
                              >
                                Passed Student
                              </Button>
                            </Modal.Footer>
                          </Modal>
                          <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={leftmodalShow}
                          >
                            <Modal.Header
                              closeButton
                              style={{ outline: "none" }}
                              onClick={() =>
                                this.setState({ leftmodalShow: false })
                              }
                            >
                              <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.modelHeading}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure to would like to left Student?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  this.setState({ leftmodalShow: false })
                                }
                              >
                                Close
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => this.leftStudentHandle()}
                              >
                                Left Student
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </span>
                      ) : null}

                      {this.props.data.approved === false ? (
                        <img
                          src={require("../../assets/copy.PNG")}
                          width="150"
                          height="200"
                        />
                      ) : null}
                      <div className="_status_div">
                        {this.props.data.approved === "Pending" ? (
                          <img
                            src={this.props.data.PaidChalan}
                            width="150px"
                            height="200px"
                          />
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          <span style={{ color: "black" }}>Status: </span>{" "}
                          {this.props.data.approved === "Approved"
                            ? "paid"
                            : null}
                          {this.props.data.approved === false ? "Unpaid" : null}
                          {this.props.data.approved === "Pending"
                            ? "Pending"
                            : null}
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid itemxs={12} sm={12} md={3} lg={3}>
                    {/* hflsjflksdjf */}
                    {this.props.data.course === "Mobile App Development" ||
                    this.props.data.course === "Video Editing" ? (
                      <div
                        onClick={() =>
                          this.props.data.approved !== "Pending"
                            ? this.setState({ modalShow: false })
                            : this.setState({
                                modalShow: true,
                                viewChlan: this.props.data.certificate,
                                modelHeading: "Certificate",
                              })
                        }
                      >
                        {this.props.data.approved === "Approved" ? (
                          <img
                            src={this.props.data.certificate}
                            width="150px"
                            height="200px"
                          />
                        ) : null}
                        {this.props.data.approved === false ? (
                          <img
                            src={require("../../assets/copy.PNG")}
                            width="150"
                            height="200"
                          />
                        ) : null}
                        {this.props.data.approved === "Pending" ? (
                          <img
                            src={this.props.data.certificate}
                            width="150px"
                            height="200px"
                          />
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          <span style={{ color: "black", marginLeft: 35 }}>
                            Certificate
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

export default CollapsePage;
