import React from "react";
import { CollapsePage } from "../../Components";
import "./styles.css";
import firebaseApp from "../../Config/Firebase/Firebase";
import TemporaryDrawer from "./../../Components/Navbar/Navbar";
import Grid from "@material-ui/core/Grid";

let database = firebaseApp.database().ref("/");
class Web extends React.Component {
  constructor() {
    super();
    this.state = {
      rollNumber: null,
      Students: [],
      filterArray: [],
      status: "All",
      batches: [],
      selectedBatch: false,
      searchType: "",
      mobileNum: "",
    };
  }

  getBatches = () => {
    let arr = [];
    database.child("batches/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      arr.push(data);
      this.setState({ batches: arr });
    });
  };

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getBatches();
        console.log("admin is login");
      } else {
        this.props.history.push("/");
      }
    });

    let arr = [];
    var rollNum = 100;
    database.child("Registration/").on("child_added", (res) => {
      let data = res.val();
      if (data.course === "Social Media Marketing") {
        console.log("_____________>>>>", data)
        data.rollNum = rollNum++;
        if (data.approved !== false) {
          arr.push(data);
        }
        this.setState({ Students: arr });
      }
    });
  }

  render() {
    console.log(this.state.searchType);

    var filterArr = [];
    let { status, mobileNum, batches, selectedBatch, searchType } = this.state;

    if (searchType !== "") {
      if (searchType === "status") {
        if (this.state.status !== "All") {
          filterArr = this.state.Students.filter((users) => {
            return users && users.approved === this.state.status;
          });
        } else {
          filterArr = this.state.Students.filter((users) => {
            return users;
          });
        }
      } else if (selectedBatch !== false) {
        if (searchType === "batch") {
          if (selectedBatch !== "All") {
            filterArr = this.state.Students.filter((users) => {
              return users && users.batch === selectedBatch;
            });
          } else {
            filterArr = this.state.Students.filter((users) => {
              return users;
            });
          }
        }
      } else {
        filterArr = this.state.Students.filter((users) => {
          console.log(users.mobileNum);
          return (
            users && Number(users.mobileNum) === Number(this.state.mobileNum)
          );
        });
      }
    } else {
      // alert("sjkfhsjkh")
      filterArr = this.state.Students.filter((users) => {
        return users;
      });
    }
    return (
      <div>
        <TemporaryDrawer props={this.props.history} />
        <div className="_body">
          <div className="title">Registered Students</div>

          <div className="_filter_view1">
            {/* STUDENT FILETER DROPDOWN */}
            <div className="_student-filter">
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={3} lg={3}>
                  <select
                    name="select"
                    className="__dropdown"
                    onChange={(event) =>
                      this.setState({
                        selectedBatch: event.target.value,
                        searchType: "batch",
                      })
                    }
                  >
                    <option value="All"> All </option>
                    {batches.map((val, i) => {
                      return (
                        <option value={val.batch_name} key={i}>
                          {val.batch_name}
                        </option>
                      );
                    })}
                  </select>
                </Grid>

                <Grid xs={12} sm={12} md={3} lg={3}>
                  <input
                    type="number"
                    style={{ outline: "none" }}
                    placeholder="Enter Mobile Number"
                    value={this.state.mobileNum}
                    onChange={(event) =>
                      this.setState({
                        mobileNum: event.target.value,
                        searchType: "number",
                      })
                    }
                    style={{ width: 150 }}
                  />
                </Grid>

                <Grid xs={12} sm={12} md={3} lg={3}>
                  <select
                    name="select"
                    className="__dropdown"
                    onChange={(event) =>
                      this.setState({
                        status: event.target.value,
                        mobileNum: null,
                        searchType: "status",
                      })
                    }
                  >
                    <option value="All"> All </option>
                    <option value="Approved">Approved </option>
                    <option value="Pending">Unapproved </option>
                  </select>
                </Grid>
                <Grid xs={10} sm={10} md={3} lg={3}>
                  <button className="button" style={{ marginTop: 5 }}>
                    Search
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>

          {filterArr.length !== 0 ? (
            filterArr.map((val, i) => {
              return <CollapsePage data={val} key={i} />;
            })
          ) : (
            <h4>there is now any student enroled yet!</h4>
          )}
        </div>
      </div>
    );
  }
}

export default Web;
